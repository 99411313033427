import RadioGroup from '@material-ui/core/RadioGroup'
import type { DialogProps, DropdownSelectOption } from '@ui/paintscout'
import { DropdownSelect, Tooltip, Typography } from '@ui/paintscout'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputField,
  InputLabel,
  KeypadInputField,
  MenuItem,
  Select,
  useClientOptions
} from '@ui/paintscout'
import clamp from 'lodash/clamp'
import Radio from '@ui/paintscout/src/Radio'
import AlertIcon from '@material-ui/icons/ErrorOutline'
import { formatCurrency, getObjectLabels, getQuoteOptions, hasIntegrationInterface } from '@paintscout/util/builder'
import type { QuoteDocument, UserPreferencesDocument } from 'paintscout'
import React, { useState } from 'react'
import PaymentDialogRightContent from '../../PaymentDialogRightContent'
import { makeStyles } from '@material-ui/core'
import { useDialogs } from '@ui/core'

export interface ConfiguredPaymentRequest {
  amount: number
  note: string
  deposit: boolean
}

export interface ConfigurePaymentDialogProps extends DialogProps {
  quote: QuoteDocument
  preferences: UserPreferencesDocument
  buttonLabel?: string
  backOnCancel?: boolean
  isRequest?: boolean
  hideSurcharge?: boolean
  onCancel: () => void
  onConfirm: (args: ConfiguredPaymentRequest) => void
}

type PaymentType = 'full' | 'custom'

const useStyles = makeStyles((theme) => ({
  customAmountInput: {
    marginBottom: theme.spacing(1)
  },
  dialogContent: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 3)
    }
  },
  dialogPaper: {
    maxWidth: '600px',
    minWidth: '600px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      minWidth: '100%'
    }
  },
  marginWrapper: {
    marginTop: theme.spacing(1)
  },
  splitInputRight: {
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0)
    }
  },
  splitInputLeft: {
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(0)
    }
  },
  alertIcon: {
    width: '16px',
    height: '16px',
    marginTop: '4px',
    color: theme.palette.text.secondary
  }
}))

const ConfigurePaymentDialog = ({
  onCancel,
  onConfirm,
  quote,
  isRequest,
  buttonLabel,
  backOnCancel,
  preferences,
  hideSurcharge,
  ...otherProps
}: ConfigurePaymentDialogProps) => {
  const { balance_due, amount_paid } = quote.totals
  const classes = useStyles()

  const { options } = useClientOptions()
  const { currency } = getObjectLabels({ options, quote })
  const { dismissAllDialogs } = useDialogs()

  const hasStripe = hasIntegrationInterface({ preferences, options, integration: 'stripe' })
  const hasPayrix = hasIntegrationInterface({ preferences, options, integration: 'payrix' })
  const hasPayments = (hasStripe || hasPayrix) && balance_due > 0

  const { allowPaymentsOnQuotes, allowedDepositTypes, allowPaymentsOnInvoices, allowACHPaymentsOnInvoices } =
    getQuoteOptions({ options, quote })

  const acceptCC = quote.is_invoice
    ? allowPaymentsOnInvoices
    : allowPaymentsOnQuotes && ['credit', 'both'].includes(allowedDepositTypes)

  const acceptACH = quote.is_invoice
    ? allowACHPaymentsOnInvoices
    : allowPaymentsOnQuotes && ['ach', 'both'].includes(allowedDepositTypes)

  const { surcharge } = getQuoteOptions({ options, quote })
  const showSurcharge = !!surcharge?.enabled && surcharge?.value > 0 && acceptCC && !hideSurcharge

  const [paymentType, setPaymentType] = useState<PaymentType>('full')
  const [customType, setCustomType] = useState<'percentage' | 'amount'>('amount')
  const [percentageType, setPercentageType] = useState<'balanceDue' | 'total'>(
    amount_paid === 0 ? 'total' : 'balanceDue'
  )
  const [customAmount, setCustomAmount] = useState<{ percentage: number; amount: number }>({
    percentage: 100,
    amount: balance_due
  })

  const [note, setNote] = useState<string>(localStorage.getItem(`configure-payment-note-${quote._id}`) || '')

  const leftButton = (
    <Button onClick={onCancel} variant={'text'}>
      {backOnCancel ? '< Back' : 'Cancel'}
    </Button>
  )

  const getAmountFromPercentageType = (percentageType: 'balanceDue' | 'total') => {
    return percentageType === 'balanceDue'
      ? (customAmount.percentage / 100) * balance_due
      : (customAmount.percentage / 100) * (balance_due + amount_paid)
  }

  const percentageTypeOptions: DropdownSelectOption[] = [
    {
      label: 'Total',
      value: 'total',
      disabled: getAmountFromPercentageType('total') > balance_due
    },
    { label: 'Balance Due', value: 'balanceDue', disabled: amount_paid === 0 }
  ]

  const formatPercentageType = (value: 'balanceDue' | 'total') => {
    return value === 'balanceDue'
      ? `Balance Due (${formatCurrency({ options, value: balance_due })})`
      : `Total (${formatCurrency({ options, value: balance_due + amount_paid })})`
  }

  const getPaymentAmount = (result?: boolean) => {
    if (paymentType === 'full') {
      return balance_due
    } else {
      if (result || customType === 'amount') {
        return customAmount.amount || 0
      } else {
        return customAmount.percentage || 0
      }
    }
  }

  const handleCustomAmountChange = (key: 'amount' | 'percentage', val: string) => {
    const total = percentageType === 'balanceDue' ? balance_due : balance_due + amount_paid
    const parsed = clamp(parseFloat(val), 0, key === 'amount' ? total : 100)
    const amount = key === 'amount' ? parsed : (parsed / 100) * total
    const roundedAmount = Math.round(amount * 100) / 100 // Round to two decimal places
    if (key === 'amount') {
      setCustomAmount({ amount: clamp(roundedAmount, 0, balance_due), percentage: (parsed / total) * 100 })
    } else {
      setCustomAmount({ amount: clamp(roundedAmount, 0, balance_due), percentage: parsed })
    }
    if (roundedAmount >= balance_due) {
      setPercentageType('balanceDue')
    }
  }

  const handlePercentageTypeChange = (val: 'balanceDue' | 'total') => {
    setPercentageType(val)
    if (val === 'balanceDue') {
      setCustomAmount({ amount: (customAmount.percentage / 100) * balance_due, percentage: customAmount.percentage })
    } else {
      setCustomAmount({
        amount: (customAmount.percentage / 100) * (balance_due + amount_paid),
        percentage: customAmount.percentage
      })
    }
  }

  const handleConfirm = () => {
    localStorage.removeItem(`configure-payment-note-${quote._id}`)
    onConfirm({ amount: getPaymentAmount(true), note, deposit: false })
  }

  const handleEditPaymentSettings = () => {
    localStorage.setItem(`configure-payment-note-${quote._id}`, note)
    const channel = new BroadcastChannel('quote-sidebar')
    channel.postMessage('edit-payment-settings')
    dismissAllDialogs()
  }

  const showSplitPercentageInputs = customType === 'percentage' && paymentType === 'custom' && balance_due > 0

  const getDisclaimer = () => {
    const disclaimerParts: string[] = []

    if (showSurcharge) {
      disclaimerParts.push(`A ${surcharge.value}% surcharge will be added to the total`)
      if (acceptCC && acceptACH) {
        disclaimerParts.push('if the payment is made by credit card')
      }
      disclaimerParts[disclaimerParts.length - 1] += '.' // Add a period to the last part
    }

    if (typeof BroadcastChannel !== 'undefined') {
      disclaimerParts.push('Click below to edit payment options and surcharge settings.')
    } else {
      disclaimerParts.push('Payment options and surcharge settings can be edited from the sidebar menu.')
    }

    return disclaimerParts.join(' ')
  }

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} onClose={onCancel} fullWidth={true} {...otherProps}>
      <DialogTitle
        fade={false}
        rightContent={
          <PaymentDialogRightContent
            quote={quote}
            options={options}
            includeSurcharge={false}
            paymentAmount={getPaymentAmount(true)}
            showCurrency={hasPayrix}
            balanceDueOverride={
              paymentType === 'custom' && customType === 'percentage' && percentageType === 'total'
                ? balance_due + amount_paid
                : undefined
            }
          />
        }
      >
        Configure Payment
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={1}>
          <Grid container alignContent="space-between" item xs={12} alignItems="center">
            <Grid item>
              <InputLabel shrink style={{ width: '150px' }}>
                Payment Amount
              </InputLabel>
            </Grid>
            <Grid item>
              <RadioGroup row={true} value={paymentType} onChange={(e, val) => setPaymentType(val as PaymentType)}>
                <FormControlLabel
                  label={`Full Amount (${formatCurrency({ options, value: balance_due })})`}
                  value={'full'}
                  control={<Radio color={'primary'} />}
                />
                <FormControlLabel label={'Custom'} value={'custom'} control={<Radio color={'primary'} />} />
              </RadioGroup>
            </Grid>
          </Grid>

          {paymentType === 'custom' && (
            <Grid
              item
              xs={12}
              container
              justifyContent="space-between"
              alignContent="flex-start"
              className={classes.marginWrapper}
            >
              <Grid
                item
                xs={12}
                sm={showSplitPercentageInputs ? 6 : 12}
                className={showSplitPercentageInputs ? classes.splitInputLeft : undefined}
              >
                <KeypadInputField
                  className={classes.customAmountInput}
                  value={getPaymentAmount().toString()}
                  fullWidth={true}
                  format={'price'}
                  label={`Amount (${customType === 'amount' ? currency.symbol : '%'})`}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleCustomAmountChange(customType, e.target.value)
                  }
                  autoSelect={true}
                  endAdornment={
                    <Select
                      onClick={(e: React.MouseEvent) => e.stopPropagation()}
                      value={customType}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        setCustomType(e.target.value as 'amount' | 'percentage')
                      }
                      renderValue={(value: string) => {
                        return <span>{value === 'percentage' ? `%` : `$`}</span>
                      }}
                    >
                      <MenuItem value="amount">{currency.symbol}</MenuItem>
                      <MenuItem value="percentage">%</MenuItem>
                    </Select>
                  }
                />
              </Grid>
              {showSplitPercentageInputs && (
                <Grid item xs={12} sm={6} className={classes.splitInputRight}>
                  <DropdownSelect
                    variant="single"
                    label="Calculated From"
                    options={percentageTypeOptions}
                    value={percentageTypeOptions.find((option) => option.value === percentageType)}
                    onChange={(option) => handlePercentageTypeChange(option.value as 'balanceDue' | 'total')}
                    formatOptionLabel={(option, placement) => (
                      <Typography color="textPrimary">
                        {placement === 'menu-item' ? formatPercentageType(option.value) : option.label}
                      </Typography>
                    )}
                  />
                </Grid>
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            <InputField
              label={isRequest ? 'Note to Customer' : 'Note'}
              value={note}
              multiline={true}
              rows={4}
              name={'note'}
              fullWidth={true}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNote(e.target.value)}
            />
          </Grid>
          {(isRequest || showSurcharge) && hasPayments && (
            <>
              <Grid item xs={12} container justifyContent="space-between">
                <div style={{ width: 'fit-content' }}>
                  <AlertIcon className={classes.alertIcon} />
                </div>
                <div style={{ width: 'calc(100% - 24px)' }}>
                  <Typography variant="caption" color="textSecondary">
                    {getDisclaimer()}
                    {typeof BroadcastChannel === 'undefined' && (
                      <Tooltip
                        placement="right"
                        content={
                          <img
                            width={300}
                            src={
                              'https://res.cloudinary.com/taptapquote/image/upload/v1743084643/paintscout/payment-settings-gif.gif'
                            }
                          />
                        }
                      >
                        <></>
                      </Tooltip>
                    )}
                  </Typography>
                </div>
              </Grid>
              {typeof BroadcastChannel !== 'undefined' && (
                <Grid item xs={12} className={classes.marginWrapper}>
                  <Button variant="dialog" onClick={handleEditPaymentSettings}>
                    Payment Settings
                  </Button>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions leftButton={leftButton}>
        {buttonLabel ? (
          <Button
            onClick={handleConfirm}
            variant={'contained'}
            disabled={paymentType === 'custom' && customAmount.amount === 0}
          >
            {buttonLabel}
          </Button>
        ) : (
          <Button
            onClick={handleConfirm}
            variant={'contained'}
            disabled={paymentType === 'custom' && customAmount.amount === 0}
          >
            {`Pay ${formatCurrency({ options, value: getPaymentAmount(true) })}`}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ConfigurePaymentDialog
