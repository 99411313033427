import React from 'react'
import { Button, Grid, Typography, Hidden, useClientOptions, useDialogs, useUser } from '@ui/paintscout'
import { makeStyles } from '@material-ui/core'
import { useQuote } from '../context'
import { formatCurrency, getQuoteOptions, hasIntegrationInterface } from '@paintscout/util/builder'
import { CollectPayrixPaymentDialog, CollectStripePaymentDialog } from '../dialogs'
import type { Payment } from 'paintscout'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(3, 4),
    background: theme.palette.grey[200],
    borderRadius: theme.spacing(1.5),
    width: 954,
    maxWidth: '100%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: 718
    },
    [theme.breakpoints.down('sm')]: {
      width: '96%'
    }
  },
  mobileMargin: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2)
    }
  },
  noteContainer: {
    marginTop: theme.spacing(2)
  },
  ofTotal: {
    color: theme.palette.text.disabled
  }
}))

export interface PaymentRequestProps {
  paymentRequest: Payment
}

export default function PaymentRequest({ paymentRequest }: PaymentRequestProps) {
  const { openDialog, dismissDialog } = useDialogs()
  const { options, clientId } = useClientOptions()
  const { quote } = useQuote()
  const hasPaymentError = quote.paymentError
  const classes = useStyles()
  const { preferences } = useUser()
  const hasStripe = hasIntegrationInterface({ preferences, options, integration: 'stripe' })
  const hasPayrix = hasIntegrationInterface({ preferences, options, integration: 'payrix' })
  const hasPayments = hasStripe || hasPayrix

  const { allowPaymentsOnInvoices, allowPaymentsOnQuotes, allowedDepositTypes, allowACHPaymentsOnInvoices } =
    getQuoteOptions({ options, quote })

  const acceptCC = quote.is_invoice
    ? allowPaymentsOnInvoices
    : allowPaymentsOnQuotes && ['credit', 'both'].includes(allowedDepositTypes)

  const acceptACH = quote.is_invoice
    ? allowACHPaymentsOnInvoices
    : allowPaymentsOnQuotes && ['ach', 'both'].includes(allowedDepositTypes)

  const showPayButton = hasPayments && (acceptCC || acceptACH)

  const PaymentDialog = hasPayrix ? CollectPayrixPaymentDialog : CollectStripePaymentDialog
  const handlePay = () => {
    openDialog(PaymentDialog, {
      quote,
      options,
      companyId: clientId,
      note: paymentRequest.note,
      isDeposit: paymentRequest.deposit,
      amount: paymentRequest.amount,
      source: 'payment-request',
      paymentRequestId: paymentRequest.id,
      paymentType: acceptCC && acceptACH ? 'both' : acceptACH ? 'ach' : 'credit',
      checkForMismatch: true,
      onCancel: dismissDialog,
      onConfirm: () => {
        window.location.reload()
      }
    })
  }

  const PayButton = () =>
    showPayButton && (
      <Button disabled={hasPaymentError} onClick={handlePay}>
        {acceptACH ? 'Pay Now' : 'Pay by Credit Card'}
      </Button>
    )

  return (
    <Grid container className={classes.root} justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h2">Payment Request</Typography>
        <Typography variant="h3">
          {`${formatCurrency({
            options,
            value: paymentRequest?.amount
          })}`}

          <span className={classes.ofTotal}>{` of ${formatCurrency({
            options,
            value: quote.totals?.balance_due
          })}`}</span>
        </Typography>
      </Grid>
      <Hidden xsDown>
        <Grid item className={classes.mobileMargin}>
          <PayButton />
        </Grid>
      </Hidden>
      {!!paymentRequest.note && (
        <Grid item xs={12} className={classes.noteContainer}>
          <Typography>
            <strong>Estimator's Note: </strong>
            {paymentRequest.note}
          </Typography>
        </Grid>
      )}
      <Hidden smUp>
        <Grid item container justifyContent="center">
          <Grid item className={classes.mobileMargin}>
            <PayButton />
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  )
}
